import { notification } from 'antd';
import { saveNotificationSelector } from './constants';
import i18next from 'i18next';
import { NotificationTypes } from '../../enums';
import { NotificationKeys } from './enums';
import { OpenNotificationProps } from './types';
import { appState } from '../../store';
import ContextualSaveBar from './ContextualSaveBar';

notification.config({ placement: 'bottom' });

export function successNotification(message: string, duration?: number | null): void {
  openNotification({ message, duration });
}

const errorNotificationStyles = { backgroundColor: '#FF4D4F' };

export function errorNotification(message: string = i18next.t('something_went_wrong'), duration?: number | null): void {
  openNotification({
    message,
    notificationType: NotificationTypes.info,
    style: errorNotificationStyles,
    duration,
  });
}

export function showSaveSuccessNotification(): void {
  openNotification({
    message: i18next.t('save.settings_saved'),
  });
}

export function showSaveErrorNotification(): void {
  errorNotification(i18next.t('save.save_setting_error'));
}

export function openBottomRightNotification(props: OpenNotificationProps): void {
  openNotification({
    className: 'bottom-right-notification-container',
    placement: 'bottomRight',
    style: { marginBottom: 80 },
    duration: null,
    ...props,
  });
}

export function closeSaveNotification(): void {
  if (appState.isShopifyEmbedded) {
    ContextualSaveBar.hide();
    return;
  }

  resetSaveNotificationAnimation();
  closeNotification(NotificationKeys.saveNotificationKey);
}

export function closeSyncInProgressNotification(): void {
  notification.close(NotificationKeys.syncInProgressNotificationKey);
}

function openNotification(props: OpenNotificationProps): void {
  const customStyles = props.style || {};
  const notificationProps = {
    className: 'toast-notification-container',
    duration: 3,
    ...props,
    style: {
      marginBottom: 8,
      backgroundColor: '#001628',
      borderRadius: 5,
      ...customStyles,
    },
  };

  switch (props.notificationType) {
    case NotificationTypes.info:
      notification.info(notificationProps);
      break;
    case NotificationTypes.success:
      notification.success(notificationProps);
      break;
    case NotificationTypes.error:
      notification.error(notificationProps);
      break;
    default:
      notification.open(notificationProps);
  }
}

export function closeNotification(key: string): void {
  notification.close(key);
}

export function shakeSaveNotification(): void {
  const notification = getSaveNotificationElement();
  if (notification) {
    notification.style.animation = 'none';
    void notification.offsetHeight;
    notification.style.animation = 'shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both';
  }
}

export function resetSaveNotificationAnimation(): void {
  const notification = getSaveNotificationElement();
  if (notification) {
    notification.style.animation = '';
  }
}

function getSaveNotificationElement(): HTMLElement | null {
  return document.querySelector(saveNotificationSelector);
}
