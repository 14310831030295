import moment from 'moment';

import { DateRanges } from './enums';

const today = moment().format('YYYY-MM-DD');

const dateRanges: Record<DateRanges | string, { id: DateRanges; startDate: moment.Moment; endDate: moment.Moment }> = {
  today: {
    startDate: moment(today),
    endDate: moment(),
    id: DateRanges.TODAY,
  },
  yesterday: {
    startDate: moment(today).subtract(1, 'day'),
    endDate: moment().subtract(1, 'day').endOf('day'),
    id: DateRanges.YESTERDAY,
  },
  last_7_days: {
    startDate: moment(today).subtract(7, 'days'),
    endDate: moment(),
    id: DateRanges.LAST_7_DAYS,
  },
  last_30_days: {
    startDate: moment(today).subtract(30, 'days'),
    endDate: moment(),
    id: DateRanges.LAST_30_DAYS,
  },
  current_month: {
    startDate: moment(today).startOf('month'),
    endDate: moment(),
    id: DateRanges.CURRENT_MONTH,
  },
  last_month: {
    id: DateRanges.LAST_MONTH,
    startDate: moment(today).subtract(1, 'month').startOf('month'),
    endDate: moment(today).subtract(1, 'month').endOf('month'),
  },
  this_year: {
    id: DateRanges.THIS_YEAR,
    startDate: moment(today).startOf('year'),
    endDate: moment(),
  },
  last_year: {
    id: DateRanges.LAST_YEAR,
    startDate: moment().subtract(1, 'year').startOf('year'),
    endDate: moment().subtract(1, 'year').endOf('year'),
  },
};

const initialDate = dateRanges.last_30_days;

export { dateRanges, initialDate };
