interface SaveBarElement extends HTMLElement {
  show: () => void;
  hide: () => void;
}

export default class ContextualSaveBar {
  public static readonly discardButtonId = 'shopify-contextual-discard-button';
  public static readonly saveButtonId = 'shopify-contextual-save-button';
  public static readonly barId = 'shopify-contextual-save-bar';

  private static get saveBar(): SaveBarElement {
    return document.getElementById(this.barId) as SaveBarElement;
  }

  public static show(): void {
    this.saveBar.show();
  }

  public static hide(): void {
    if (!this.saveBar) {
      return;
    }

    this.saveBar.hide();
  }

  public static onSaveClick(onSaveClick: () => void, once: boolean = true): void {
    this.cleanEventListener(this.saveButtonId);
    document.getElementById(this.saveButtonId)?.addEventListener('click', onSaveClick, { once });
  }

  public static onDiscardClick(onDiscardClick: () => void, once: boolean = true): void {
    this.cleanEventListener(this.discardButtonId);
    document.getElementById(this.discardButtonId)?.addEventListener('click', onDiscardClick, { once });
  }

  private static cleanEventListener(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      const newElement = element.cloneNode(true) as HTMLElement;
      element.parentNode?.replaceChild(newElement, element);
    }
  }

  public static setLoading(isLoading: boolean): void {
    if (!this.saveBar) {
      return;
    }

    const saveBar = document.getElementById(this.saveButtonId)!;
    if (isLoading) {
      saveBar.setAttribute('loading', '');
    } else {
      saveBar.removeAttribute('loading');
    }
  }
}
