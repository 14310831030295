import posthog from 'posthog-js';
import { appState } from 'store';

import type { ShopifyPlanNames } from '@rapidsearch/shared/lib/enums';
import TestStore from '@rapidsearch/shared/lib/utils/TestStore';
import { config } from '../../config';
import { isEmbeddedAdmin } from '../constants';
import { isIcpStore } from '../icpDetection';
import { posthogHost } from './constants';
import { PosthogEventProperties, PosthogEvents, SyncTypes } from './types';

class PosthogHandler {
  static isPostHogLoaded = false;

  private static lastPageViewUrl = '';

  public static initPosthog(): void {
    const posthogKey = PosthogHandler.getPosthogKey();

    if (isEmbeddedAdmin && posthogKey) {
      PosthogHandler.identifyUser(posthogKey);
    }

    PosthogHandler.isPostHogLoaded = true;
  }

  private static identifyUser(posthogKey: string): void {
    const { storeId, settings } = appState;

    posthog.init(posthogKey, {
      capture_pageview: false,
      api_host: posthogHost,
      loaded: (posthogInstance) => {
        posthogInstance.identify(storeId, { email: storeId });
      },
    });

    posthog.setPersonProperties({
      rs_plan: settings.current_plan,
      is_icp: isIcpStore(),
      engine: settings.webshop_engine,
      country_code: settings.country_code,
    });
  }

  private static getPosthogKey(): string | null | undefined {
    const { testPosthogKey, livePosthogKey } = config;

    if (!testPosthogKey && !livePosthogKey) {
      return null;
    }

    if (testPosthogKey && !livePosthogKey) {
      return testPosthogKey;
    }

    const { storeId, settings } = appState;
    const testStore = new TestStore(storeId, settings.plan_name as unknown as ShopifyPlanNames);
    const isRapidSearchTestStore =
      testStore.isShoprenterTestStore ||
      testStore.isCustomTestStore ||
      (testStore.isShopifyTestStore && appState.settings.shop_owner === 'Rapid Search');

    return isRapidSearchTestStore ? testPosthogKey : livePosthogKey;
  }

  public static triggerSyncProgressBarFinishedEvent(): void {
    PosthogHandler.sendPosthogEvent(PosthogEvents.SyncProgressbarFinished, {
      type: SyncTypes.FirstSync,
    });
  }

  public static triggerSubscriptionEvent(): void {
    const { subscription_info } = appState.settings;
    const { price, plan } = subscription_info;

    PosthogHandler.sendPosthogEvent(PosthogEvents.SubscriptionUpdate, {
      plan_name: plan,
      price,
      billing_cycle: subscription_info.billing_cycle,
    });
  }

  public static triggerFreePlanEvent(): void {
    const { current_plan } = appState.settings;

    PosthogHandler.sendPosthogEvent(PosthogEvents.SubscriptionUpdate, {
      plan_name: current_plan,
      price: 0,
    });
  }

  public static triggerWebVital(event: PosthogEvents.LCP | PosthogEvents.FCP, properties: PosthogEventProperties): void {
    PosthogHandler.sendPosthogEvent(event, properties);
  }

  public static sendPosthogPageViewEvent(): void {
    if (PosthogHandler.lastPageViewUrl === location.href) {
      return;
    }

    const eventIsSent = PosthogHandler.sendPosthogEvent(PosthogEvents.PageView);

    if (eventIsSent) {
      PosthogHandler.lastPageViewUrl = location.href;
    }
  }

  private static sendPosthogEvent(event: PosthogEvents, properties?: PosthogEventProperties): boolean {
    if (!PosthogHandler.isPostHogLoaded) {
      return false;
    }

    posthog.capture(event, properties);
    return true;
  }
}

export default PosthogHandler;
