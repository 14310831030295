import { store } from '@risingstack/react-easy-state';
import { defaultCampaignValues } from 'initialize/campaigns.contants';
import cloneDeep from 'lodash/cloneDeep';
import { AppState, HistoryExtended, PartialSettings, Settings } from 'types.d';
import { defaultSidebarWidth } from 'utils/constants';
import { dateRanges, initialDate } from 'utils/statistics/constants';

import { SearchAnalyticsTabs } from './enums';
import { getCustomDate, getIsMarketplaceAdmin, getStatisticsDateInfo, saveSettingRequest } from './utils/statelessFunctions';
import { DateRanges } from './utils/statistics/enums';

const isMobile = window.innerWidth < 768;
const isTablet = window.innerWidth < 992;
let _history: HistoryExtended;

const defaultAppState = store({
  homeSettingsLoading: false,
  initializationError: false,
  onboarding: {
    currentStep: 0,
  },
  planHasExpired: false,
  isFree: false,
  searchStatsError: false,
  limitExceeded: false,
  isPaid: false,
  isTrial: false,
  isCancelled: false,
  expirationDate: undefined,
  installDate: undefined,
  daysAfterInstall: 0,
  isShopify: false,
  isShoprenter: false,
  previewIsDesktop: true,
  shoprenterTermsAccepted: false,
  preparingStore: false,
  manualSyncDisabled: false,
  billing: {},
  billingCycle: undefined,
  mounted: false,
  storeId: null,
  adminUrl: '',
  appUrl: '',
  customDomain: null,
  language: '',
  isMobile,
  isTablet,
  sidebarCollapsed: isMobile,
  isUnsuccessfulSubscription: false,
  originalSettings: undefined,
  searchAnalyticsTab: SearchAnalyticsTabs.TrendingSearches,
  statisticsDate: {
    dateRangeIsOpen: false,
    dateSelectIsOpen: false,
    startDate: initialDate.startDate,
    endDate: initialDate.endDate,
    defaultValue: initialDate.id,
    isCustom: false,
  },
  multivariateEnabled: false,
  multivariateSegment: 'all',
  setCustomDate: ([start, end]: [moment.Moment, moment.Moment], [startString, endString]: [string, string]) => {
    const { startDate, endDate } = getCustomDate(startString, endString);

    defaultAppState.statisticsDate = {
      ...defaultAppState.statisticsDate,
      isCustom: true,
      ...getStatisticsDateInfo({
        id: `${startDate} - ${endDate}`,
        endDate: end,
        startDate: start.startOf('day'),
      }),
    };

    defaultAppState.saveSettingsAndResetAppState({ statisticsDateRange: `${startString}/${endString}` });
  },
  changeDateRange: (value: DateRanges) => {
    defaultAppState.statisticsDate = {
      ...defaultAppState.statisticsDate,
      isCustom: false,
      ...getStatisticsDateInfo(dateRanges[value]),
    };

    defaultAppState.saveSettingsAndResetAppState({ statisticsDateRange: value });
  },
  changeSegment: (value: string) => {
    defaultAppState.multivariateSegment = value;
  },
  updateSyncSettings: (settings: Settings) => {
    defaultAppState.isFirstSyncFinished = !!settings.last_import;
    defaultAppState.settings.last_import = settings.last_import;
    defaultAppState.settings.resultpage_filters = settings.resultpage_filters;
    defaultAppState.resetAppState({
      last_import: settings.last_import,
      resultpage_filters: settings.resultpage_filters,
    });
  },
  resetAppState: (settings?: PartialSettings): void => {
    if (settings) {
      for (const key of Object.keys(settings) as Array<keyof typeof settings>) {
        // @ts-ignore
        defaultAppState.originalSettings[key] = cloneDeep(defaultAppState.settings[key]);
      }
    } else {
      defaultAppState.originalSettings = cloneDeep(defaultAppState.settings);
    }
  },
  saveSettingsAndResetAppState: async (settings: PartialSettings): Promise<boolean> => {
    const response = await saveSettingRequest(defaultAppState.storeId, settings);
    defaultAppState.resetAppState(settings);
    return response;
  },
  themes: {
    mainThemeId: 0,
    loading: true,
    list: {},
  },
  initializing: true,
  isMarketplaceAdmin: getIsMarketplaceAdmin(),
  isMarketplace: false,
  cachedData: {},
  isDemo: false,
  campaign: defaultCampaignValues,
  expoCampaign: {
    isActive: false,
    endAsTimeStamp: 0,
    end: undefined,
  },
  isSuperAdmin: false,
  disabledFeatures: {
    merchandising: false,
    shoprenterBadges: false,
    shopifyFilters: false,
    attributeFilters: false,
  },
  savingSettings: false,
  unsavedChanges: false,
  unsavedChangesModalVisible: false,
  hasMultiLangAccess: false,
  togglingMultiLang: false,
  hasMarketsAccess: false,
  sideBarWidth: defaultSidebarWidth,
  reviewAdded: false,
  isTestStore: false,
  isFirstSyncFinished: false,
  isShoprenterSearchLimitReached: false,
  storefrontPreviewDomain: '',
  isAuchan: false,
  shouldShowBillingPage: false,
  isUnlimitedSessions: false,
  planEstimatedSessionUsagePercent: 0,
  isCustomUnlimitedPlan: false,
  isShoprenterOrShopify: false,
  isMagento2SuperAdmin: false,
  shopifyPartners: null,
  planOverUsePercentage: 0,
  isShopifyEmbedded: false,
  get history(): HistoryExtended {
    return _history;
  },
  set history(history: HistoryExtended) {
    _history = history;
  },
}) as unknown as AppState;

export const appState: AppState = defaultAppState;
