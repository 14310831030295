import { appState } from '../../store';
import LocalStorage from './LocalStorage';

export default class LocalStorageWithStoreId extends LocalStorage {
  private readonly itemWithStoreId: string;

  constructor(key: string, storeId?: string) {
    super(key);
    this.itemWithStoreId = `${this.key}_${storeId || appState.storeId}`;
  }

  setItem(value: string): void {
    if (LocalStorage.isSupported) {
      localStorage.setItem(this.itemWithStoreId, value || '');
    }
  }

  getItem(): string | null {
    if (LocalStorage.isSupported) {
      return localStorage.getItem(this.itemWithStoreId);
    }

    return null;
  }

  removeItem(): void {
    if (LocalStorage.isSupported) {
      localStorage.removeItem(this.itemWithStoreId);
    }
  }
}
