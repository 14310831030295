import authState from 'authentication/store';
import { AdminRoles, RestrictedFeatures } from 'enums';
import { appState } from 'store';
import { shopifyTestPlans } from 'utils/constants';

import RestrictedFeaturesChecker from './RestrictedFeaturesChecker';

export function setRestrictedFeatures() {
  setSuperAdmin();
  restrictTestStoreFeatures();
}

function setSuperAdmin(): void {
  const superAdmin = new RestrictedFeaturesChecker(RestrictedFeatures.SUPER_ADMIN).isFeatureEnabled();

  if (superAdmin) {
    authState.role = AdminRoles.SUPER_ADMIN;
  }
}

function restrictTestStoreFeatures(): void {
  const isShopifyTestPlan = shopifyTestPlans.includes(appState.settings.plan_name);
  const testModeEnabled = new RestrictedFeaturesChecker(RestrictedFeatures.TEST_STORE).isFeatureEnabled();

  appState.isTestStore = isShopifyTestPlan && !testModeEnabled;
}
